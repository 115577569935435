import api from '../../api';
import { IUserAccountsResponse, IUserTransactionsResponse } from './models';


const useNarmiAPI = () => {

 const apiURL = process.env.REACT_APP_SECRETS_API_URL;

  return api.injectEndpoints({
    endpoints: (build) => ({
      getUserAccounts: build.query<IUserAccountsResponse, void>({
        query: () => {
          return {
            url: `${apiURL}/auth/getNarmiAccounts`, 
            method: 'GET'
          };
        },
      }),
      getUserTransactions: build.query<IUserTransactionsResponse, {search?: string, records_per_page?: number;}>({
        query: ({ search, records_per_page }) => {
          const params = new URLSearchParams();
          if (search) params.append('q', search);
          if (records_per_page) params.append('per-page', records_per_page.toString());
      
          return {
            url: `${apiURL}/auth/getNarmiTransactions${params.toString() ? `?${params.toString()}` : ''}`,
            method: 'GET',
          };
        },
      }),
    }),
  });
}

export default useNarmiAPI;

