import api from "../../api";
import { IUserMxAccountsResponse, IUserMxTransactionsResponse } from "./models";


const useMXAPI = () => {

  const apiURL = process.env.REACT_APP_SECRETS_API_URL;

  return api.injectEndpoints({
    endpoints: (build) => ({
      getMXUserAccounts: build.query<IUserMxAccountsResponse, void>({
        query: () => {
          return {
            url: `${apiURL}/auth/getMXAccounts`, 
            method: 'GET'
          };
        },
      }),
      getMXUserTransactionsByAccount: build.query<IUserMxTransactionsResponse, { id: string, from_date?: string, to_date?: string, records_per_page?: number }>({
        query: ({ id, from_date, to_date, records_per_page }) => {
          const params = new URLSearchParams();
          if (from_date) params.append('from_date', from_date);
          if (to_date) params.append('to_date', to_date);
          if (records_per_page) params.append('records_per_page', records_per_page.toString());
      
          return {
            url: `${apiURL}/auth/getMXTransactions/${id}${params.toString() ? `?${params.toString()}` : ''}`,
            method: 'GET'
          };
        },
      }),  
    }),
  });
}

export default useMXAPI;
