import { useDispatch } from "react-redux";
import { setIsAuthorized, setIsLoadingAccountDetails, setNoExternalAccounts } from "../store/slices/authSlice";
import NarmiAPI from "../store/modules/NarmiAPI";
import MXAPI from "../store/modules/MXAPI";
import { useEffect, useState } from "react";
import { calculateAggregatedAccounts, calculateCashOnHand, calculateCashOnDeposit, calculateForecastedExpenses, calculateCashOnReserve, calculateTorScore } from "../utils/calculations";
import { getNarmiTransactionsByType, getMXTransactionsByType } from "../utils/getTransactionsByType";
import { TransactionType } from "../models/common";




const useAccountDetails = () => {
  const dispatch = useDispatch();
  const [getNarmiUserAccounts] = NarmiAPI().useLazyGetUserAccountsQuery();
  const [getNarmiUserTransactions] = NarmiAPI().useLazyGetUserTransactionsQuery();
  const [getMXUserAccounts] = MXAPI().useLazyGetMXUserAccountsQuery();
  const [getMXUserTransactions] = MXAPI().useLazyGetMXUserTransactionsByAccountQuery();
  const [cashOnHand, setCashOnHand] = useState('0.00');
  const [cashOnDeposit, setCashOnDeposit] = useState('0.00');
  const [cashReserve, setCashReserve] = useState(0);
  const [accountsCount, setAccountsCount] = useState(0);
  const [forecastedExpenses, setForecastedExpenses] = useState('0.00');
  const [cashflowScore, setCashflowScore] = useState(0);

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        dispatch(setIsLoadingAccountDetails(true));

        const narmiAccountData = await getNarmiUserAccounts().unwrap();

        const mxAccountData = await getMXUserAccounts().unwrap();

        if(narmiAccountData.meta.total === 1){
          dispatch(setNoExternalAccounts(true));
        }

        const externalNarmiAccounts = narmiAccountData.accounts.filter(account => account.source === 'external');
        const checkMicrList = externalNarmiAccounts.map(account => account.check_micr);

        const matchingMxAccounts = mxAccountData.accounts.filter(mxAccount => 
          checkMicrList.includes(mxAccount.account_number)
        );
      
        let threeMonthsAgo = new Date();
        threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90);
        const fromDate = threeMonthsAgo.toISOString().split('T')[0];
        
        const toDate = new Date().toISOString().split('T')[0]; 

        const narmiTransactionsData =  await getNarmiUserTransactions({
          search: `settled>${fromDate}`,
          records_per_page: 1000
        }).unwrap();
        
        const mxTransactionsData = await Promise.all(
          matchingMxAccounts.map(async (account) => {
            const transactions = await getMXUserTransactions({
              id: account.guid,
              from_date: fromDate,
              to_date: toDate,
              records_per_page: 1000
            }).unwrap();
            return transactions.transactions;
          })
        );

        const mxTransactionsDataAll = mxTransactionsData.reduce((acc, curr) => acc.concat(curr), []);
      
        //Accounts count
        setAccountsCount(calculateAggregatedAccounts(narmiAccountData.accounts.length, matchingMxAccounts.length));

        // Cash on hand
        setCashOnHand(calculateCashOnHand(narmiAccountData.accounts, matchingMxAccounts));
    
        // Cash on deposit
        setCashOnDeposit(calculateCashOnDeposit(narmiAccountData.accounts));

        // Forecasted expenses and cash reserve
        const narmiDebitTransactions = getNarmiTransactionsByType(narmiTransactionsData.transactions, TransactionType.Debit);

        const mxDebitTransactions = getMXTransactionsByType(mxTransactionsDataAll, TransactionType.Debit);

        setForecastedExpenses(calculateForecastedExpenses([...narmiDebitTransactions, ...mxDebitTransactions]));

        setCashReserve(calculateCashOnReserve([...narmiDebitTransactions, ...mxDebitTransactions], narmiAccountData.accounts, matchingMxAccounts));

        //cashflow score calculation

        // This is for next phase 
        // const baseLineBalance = (calculateBaselineBalance(narmiTransactionsData.transactions) + calculateBaselineBalance(mxTransactionsDataAll));
        // const { USL, LSL } = calculateUSLandLSL(baseLineBalance);

        setCashflowScore(calculateTorScore(narmiTransactionsData.transactions, mxTransactionsDataAll));

      } catch (error) {
        console.error("Something went wrong");
        dispatch(setIsAuthorized(false));
      } finally {
        dispatch(setIsLoadingAccountDetails(false));
      }
    };

    // This is for next phase 
    // function calculateBaselineBalance(transactions: IMxTransaction[] | ITransaction[]) {
    //   const totalBalance = transactions.reduce((sum, transaction) => sum + transaction.amount, 0);
    //   return totalBalance / transactions.length;
    // }

    // function calculateUSLandLSL(baselineBalance: number, customUSL = null, customLSL = null) {
    //   const standardUSL = baselineBalance * 1.4;
    //   const standardLSL = baselineBalance * 0.8;
    //   const USL = customUSL !== null ? customUSL : standardUSL;
    //   const LSL = customLSL !== null ? customLSL : standardLSL;
    //   return { USL, LSL };
    // }
    

    getUserDetails();
  }, [getNarmiUserAccounts, getMXUserAccounts, dispatch, getNarmiUserTransactions, getMXUserTransactions]);

  return {
    cashOnHand,
    cashOnDeposit,
    cashReserve,
    accountsCount,
    forecastedExpenses,
    cashflowScore
  }
    ;
};

export default useAccountDetails;
